<template>
  <div class="app-admin-page application-page">
    <ApplicationHeader
      name="open-apps.list"
      :count="count"
    />
    <ApplicationFilter
      class="mb-5-a"
      v-bind="filterAttrs"
      v-on="filterListeners"
    />
    <ApplicationFilterPills
      ref="pills"
      :default-filter="defaultFilter"
      :filter-sources="filterSources"
      :filter="filter"
      :filter-parser="parsePills"
      :excluded-filters="excludeKeys"
      @input="onFilterInput"
      @pills-calc="onPillsCalc"
      @clear="onFilterReset"
    />

    <OpenApplicationContentItems
      :filter="filter"
      :content-attrs="contentAttrs"
      :content-listeners="contentListeners"
      :loading="loading"
      @open="openApplication"
    />
    <ApplicationModal
      v-if="modalVisible"
      v-bind="modalAttributes"
      v-on="modalListeners"
      modal-window-class="two-columns"
      icon="filter"
      name="common.filter"
      ref="modal"
    />
  </div>
</template>
<script>
import {get, sAPIUrl} from "../../helpers/api";
import {statusColors} from "../../components/partials/ProblemTag";
import TreeProblemOptions from "../../services/TreeProblemOptions";
import {getRatingTheme} from "../../components/partials/StarsRating";
import ApplicationHeader from "../../components/admin/ApplicationHeader";
import {getApplicationDefaultMixin, getApplicationFilterMixin, getApplicationModalMixin} from "../../mixins/ScriptPage";
import {
    ContentLoader,
    FormInputElement,
    FormInputList,
    ModalController,
    ModalFormController,
    ScriptPage
} from "../../services/ScriptPage";
import ApplicationFilter from "../../components/admin/ApplicationFilter";
import ApplicationFilterPills from "../../components/admin/ApplicationFilterPills";
import ApplicationModal from "../../components/modals/ApplicationModal";
import langsMixin from "../../mixins/langsMixin";
import clientCategories from "@/config/clientCategories";
import OpenApplicationContentItems from "../../components/admin/OpenApplicationContentItems";
import {mapGetters} from "vuex";
import { AppCallType } from "../../helpers/enums";
const treeOptions = new TreeProblemOptions();

const parsedClientCategories = clientCategories.map(cat => ({ name: cat.name, value: cat.id, id: cat.id }));

const defaultFilter = {
    status: null,
    type_id: null,
    phone: "",
    product_ids: [],
    operator_ids: [],
    username: "",
    is_company: null,
    created_at_to: "",
    created_at_from: "",
    source_id: null,
    marketing_id: [],
    skip: 0,
    limit: 20,
    grade: null,
    categoryInternal: "phone",
    client_type: null,
    language_id: null,
    has_redmine: null,
    call_type: null
};

const callTypeOptions = [
    {
        id: 0,
        name: "Входящая",
        value: AppCallType.INCOMING
    },
    {
        id: 1,
        name: "Исходящая",
        value: AppCallType.OUTBOUND
    }
]
const redmineOptions = [
    {
        id: 1,
        name: "Да",
        value: 1
    },
    {
        id: 0,
        name: "Нет",
        value: 0
    }
]
export default {
    name: "Application",
    components: {
        OpenApplicationContentItems,
        ApplicationModal,
        ApplicationFilterPills,
        ApplicationFilter,
        ApplicationHeader,
    },
    mixins: [
        langsMixin,
        getApplicationDefaultMixin(new ScriptPage(
            new ContentLoader("/applications"),
            defaultFilter,
            new ModalFormController(
                new FormInputList("1", "", "", "", [
                    new FormInputElement("username", "common.table.fio", "", "input"),
                    new FormInputElement("phone", "common.table.phoneNumber", "", "input"),
                    new FormInputElement("type_id", "common.table.type", null, "select", []),
                    new FormInputElement("product_ids", "common.table.theme", [], "tree", [], [], {
                        flattenItems: [], loading: true
                    }),
                    new FormInputElement("operator_ids", "common.table.operator", [], "tree", [], [], {
                        flattenItems: [], loading: false
                    }),
                    new FormInputElement("status", "common.table.status", null, "select", []),
                    new FormInputElement("call_type", "common.line", null, "select", callTypeOptions),
                    new FormInputElement("has_redmine", "common.appealFromRedmine", null, "select", redmineOptions),
                    new FormInputElement("source_id", "common.table.sourceOfReference", null, "select", []),
                    new FormInputElement("marketing_id", "common.table.howDidTheClientFindOut", [], "tree", [], [], {
                        flattenItems: [], loading: false
                    }),
                    new FormInputElement("client_type", "common.table.clientCategory", null, "select", parsedClientCategories),
                    new FormInputElement("language_id", "common.table.languageOfAddress", null, "select", []),
                    new FormInputElement("is_company", "common.legalStatus", null, "select", []),
                    new FormInputElement("created_at_to", "", "", "hidden"),
                    new FormInputElement("created_at_from", "", "", "hidden"),
                    new FormInputElement("grade", "common.table.averageRating", null, "rating"),
                    new FormInputElement("date", "common.form.date", "", "double-date", null, [], {
                        keys: ["created_at_from", "created_at_to"],
                    }),
                ])
            ),
            new ModalController()
        )),
        getApplicationFilterMixin(defaultFilter),
        getApplicationModalMixin()
    ],
    data() {
        return {
            defaultFilter,
            branches: [],
            treeOptions,
            operatorsList: [],
            isCompanyList: [{title: "common.yes", name: "common.entity", id: 1, value: 1}, {title: "common.no", name: "common.individual", id: 0, value: 0}],
            filterCount: 0,
            excludeKeys: [
                "page", "created_at_from", "created_at_to", "grade", "categoryInternal", "skip", "limit"
            ]
        };
    },
    computed: {
        ...mapGetters({
            app_statuses: "applicationStatusesForFilter"
        }),
        filterItems() {
            return [
                {
                    type: "button",
                    key: "filter",
                    icon: "filter",
                    name: this.filterCount ? this.$t("common.filterWithCount", { count: this.filterCount }) : "common.filter",
                    theme: "primary"
                },
                {
                    type: "search-category",
                    name: "common.searchWithDots",
                    items: [
                        {
                            short: "common.phone",
                            value: "phone",
                            placeholder: "-- --- -- --",
                            mask: "##########"
                        },
                        {
                            short: "common.fio",
                            value: "username",
                            placeholder: "",
                            mask: ""
                        },
                        {
                            short: "common.table.operator",
                            value: "operator_id",
                            placeholder: "",
                            mask: ""
                        },
                    ]
                },
                {
                    type: "spacer",
                    key: "a"
                },
                {
                    name: "common.export",
                    key: "export",
                    icon: "export",
                    type: "button"
                }
            ];
        },
        filterSources() {
            return {
                status: this.app_statuses,
                type_id: this.app_types,
                operator_ids: this.operatorsList,
                is_company: this.isCompanyList,
                source_id: this.sources,
                marketing_id: this.marketingSources,
                product_ids: this.treeOptions.flatOptions,
                client_type: parsedClientCategories,
                language_id: this.languagesList,
                call_type: callTypeOptions,
                has_redmine: redmineOptions
            };
        },
        languagesList() {
            return Object.keys(this.$store.state.langsById).map(id => {
                const el = this.$store.state.langsById[id];
                return {
                    name: el.name,
                    id: +id,
                    value: +id
                };
            });
        },
        app_types() {
            return this.$store.state.dictionary.map(({id, title}) => ({id, value: +id, name: title}));
        },
        sources() {
            return this.$store.state.incomingClientSources.map(({id, name}) => ({id, value: +id, name}));
        },
        marketingSources() {
            return this.$store.state.marketing_sources.map(({id, title}) => ({id: +id, value: +id, name: title, title}));
        },

        skeletonList() {
            const list = []
            for (let index = 0; index < 10; index++) {
                list.push({
                    id: index,
                    title: "",
                    skeleton: this.loading,
                })
            }
            return list
        }
    },
    watch: {
        "$store.state.criticalLoaded": {
            handler(value) {
                if(value) {
                    const storedItems = [
                        [
                            {
                                items: this.app_statuses
                            },
                            "status"
                        ],
                        [
                            {
                                items: this.app_types
                            },
                            "type_id"
                        ],
                        [
                            {
                                items: this.isCompanyList
                            },
                            "is_company"
                        ],
                        [
                            {
                                items: this.sources
                            },
                            "source_id"
                        ],
                        [
                            {
                                items: this.marketingSources
                            },
                            "marketing_id"
                        ],
                    ];
                    storedItems.forEach(item => {
                        this.page.setFormRenderElement(item[0], item[1]);
                    });
                }
            },
            immediate: true
        },
        "$store.state.langsById": {
            handler() {
                this.page.setFormRenderElement({
                    items: this.languagesList
                }, "language_id");
            },
            immediate: true
        }
    },
    created() {
        this.loadAll().then(data => {
            const items = [
                [
                    {
                        flattenItems: this.treeOptions.flatOptions,
                        items: this.treeOptions.list,
                        loading: false
                    },
                    "product_ids"
                ],
                [
                    {
                        flattenItems: data[0],
                        items: data[0],
                        loading: false
                    },
                    "operator_ids"
                ],
                [
                    {
                        flattenItems: this.marketingSources,
                        items: this.marketingSources,
                        loading: false
                    },
                    "marketing_id"
                ]
            ];
            this.operatorsList = data[0];
            items.forEach(item => {
                this.page.setFormRenderElement(item[0], item[1]);
            });
        });
    },
    methods: {
        parsePills(filters, model) {
            const obj = [];
            const status = filters.find(f => f.key === "status");
            if(status) {
                const s = {
                    ...status,
                    theme: statusColors[model.status - 1]
                };
                obj.push(s);
            }

            if(model.grade) {
                let gradeStatus = 2;
                const grade = model.grade;
                if(grade < 2) gradeStatus = 0;
                else if(grade < 4) gradeStatus = 1;
                obj.push({
                    name: model.grade + " " + this.$t("common.forth", { count: "5" }),
                    key: "grade",
                    theme: statusColors[gradeStatus]
                });
            }
            return obj;
        },
        getRating(rating) {
            return getRatingTheme(rating);
        },
        onPillsCalc(length) {
            this.filterCount = length;
        },
        openApplication(id) {
            this.$router.push(`/open-apps/${id}?${this.page.getFilterString()}`);
        },
        onFilterInput(key, value) {
            this.page.setFilterValuesAndFetch({
                [key]: value
            });
        },
        onFormSubmit() {
            const model = this.formModel;
            this.page.formClose();
            this.page.setFilterValuesAndFetch(model);
        },
        onFilterClick(key) {
            if(key === "export") this.loadExcel();
            else if(key === "filter") this.page.openModalAndUpdateForm(this.filter);
        },
        getSource(sourceId) {
            let sourceItem = this.sources.find(source => source.id === sourceId);
            if(!sourceItem) {
                sourceItem = this.sources.find(source => source.slug === "call-center");
            }
            return sourceItem?.name;
        },
        loadAll() {
            const operators = get("/operators").then(res => {
                return res.data.map(o => ({...o, title: o.first_name + " " + o.last_name}));
            });
            const options = this.treeOptions.loadOptions(0).then(data => data.data);
            return Promise.all([
                operators,
                options
            ]);
        },
        loadExcel(){
            const link = document.createElement("a");
            link.href = sAPIUrl + "api/xlsx/apps?"+this.page.getFilterStringRequest();
            link.target = "_blank";
            link.click();
        },
        onFilterReset() {
            this.page.setFilterValuesAndFetch(defaultFilter);
        },
        onFormReset() {
            this.page.formReset();
        }
    }
};

</script>
